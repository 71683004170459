// Pagination
// This is a duplicate of the pagination styling from Bootstrap 3.3.5 (?) and should
// be safe to remove when we update Cider to a newer version of Bootstrap.

@import (reference) "bootstrap-3.0.3/mixins.less";
@import (reference) "bootstrap-3.0.3/variables.less";

.pagination-size(@padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius) {
    > li {
        > a,
        > span {
            
            padding: @padding-vertical @padding-horizontal;
            font-size: @font-size;
            line-height: @line-height;
        }

        &:first-child {
            > a,
            > span {
                .border-left-radius(@border-radius);
            }
        }

        &:last-child {
            > a,
            > span {
                .border-right-radius(@border-radius);
            }
        }
    }
}

//
// Pagination (multiple pages)
// --------------------------------------------------
.pagination {
    display: inline-block;
    padding-left: 0;
    margin: @line-height-computed 0;
    border-radius: @border-radius-base;

    > li {
        display: inline; // Remove list-style and block-level defaults
        > a,
        > span {
            border-radius: 0;
            position: relative;
            float: left; // Collapse white-space
            padding: @padding-base-vertical @padding-base-horizontal;
            line-height: @line-height-base;
            text-decoration: none;
            color: @pagination-color;
            background-color: @pagination-bg;
            border: 1px solid @pagination-border;
            margin-left: -1px;
        }

        &:first-child {
            > a,
            > span {
                margin-left: 0;
                .border-left-radius(@border-radius-base);
            }
        }

        &:last-child {
            > a,
            > span {
                .border-right-radius(@border-radius-base);
            }
        }
    }

    > li > a,
    > li > span {
        &:hover,
        &:focus {
            z-index: 3;
            color: @pagination-hover-color;
            background-color: @pagination-hover-bg;
            border-color: @pagination-hover-border;
        }
    }

    > .active > a,
    > .active > span {
        &,
        &:hover,
        &:focus {
            z-index: 2;
            color: @pagination-active-color;
            background-color: @pagination-active-bg;
            border-color: @pagination-active-border;
            cursor: default;
        }
    }

    > .disabled {
        > span,
        > span:hover,
        > span:focus,
        > a,
        > a:hover,
        > a:focus {
            color: @pagination-disabled-color;
            background-color: @pagination-disabled-bg;
            border-color: @pagination-disabled-border;
            cursor: @cursor-disabled;
        }
    }
}

// Sizing
// --------------------------------------------------

// Large
.pagination-lg {
    .pagination-size(@padding-large-vertical; @padding-large-horizontal; @font-size-large; @line-height-large; @border-radius-large);
}

// Small
.pagination-sm {
    .pagination-size(@padding-small-vertical; @padding-small-horizontal; @font-size-small; @line-height-small; @border-radius-small);
}

//== Pagination
//
//##

@pagination-color: #6BB697;
@pagination-bg: #fff;
@pagination-border: #ddd;

@pagination-hover-color: @link-hover-color;
@pagination-hover-bg: @gray-lighter;
@pagination-hover-border: #ddd;

@pagination-active-color: #fff;
@pagination-active-bg: #6BB697;
@pagination-active-border: #6BB697;

@pagination-disabled-color: @gray-light;
@pagination-disabled-bg: #fff;
@pagination-disabled-border: #ddd;


//== Pager
//
//##

@pager-bg: @pagination-bg;
@pager-border: @pagination-border;
@pager-border-radius: 15px;

@pager-hover-bg: @pagination-hover-bg;

@pager-active-bg: @pagination-active-bg;
@pager-active-color: @pagination-active-color;

@pager-disabled-color: @pagination-disabled-color;

//** Disabled cursor for form controls and buttons.
@cursor-disabled: not-allowed;
